.toolbar-img {
  height: 64px;
  width: 240px;
  position: fixed;
  left: 0;
  right: 0;
}

.bhagavan-img-toolbar {
  width: 60px;
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

#generic-page-card-content.generic-page-card-content {
  padding-bottom: 0px;
}

#app-toolbar {
  padding-right: 0px;
}
